import React from 'react';
import PropTypes from 'prop-types';
import { GeoJSONLayer } from 'react-mapbox-gl';

import { getCentroidGeoJSON } from 'screens/Property/helpers/mapApiHelpers';
import {
  conditionLayerSelected,
  conditionLayerCustom
} from '../../../PropertyMap/utilities/mapboxLayerHelpers';

const DynamicLayer = ({
  geoJSON,
  onClickShape,
  labelKey,
  isGeoJSONLabelEnabled
}) => {
  const centroidGeoJSON = getCentroidGeoJSON(geoJSON);

  let symbolLayout = {};
  let symbolPaint = {};
  if (isGeoJSONLabelEnabled) {
    symbolLayout = {
      'text-field': ['case', conditionLayerCustom, '', ['get', labelKey]],
      'text-anchor': 'center',
      'text-justify': 'center',
      'text-variable-anchor': ['center'],
      'text-radial-offset': 0,
      'text-size': 20,
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-allow-overlap': true,
      'symbol-sort-key': ['get', 'sortKey']
    };

    symbolPaint = {
      'text-color': 'white'
    };
  }

  return (
    <div
      data-testid={
        isGeoJSONLabelEnabled ? 'geojson-layer-with-labels' : 'geojson-layer'
      }
    >
      <GeoJSONLayer
        fillOnClick={onClickShape}
        data={geoJSON}
        fillPaint={{
          'fill-color': [
            'case',
            conditionLayerCustom,
            'transparent',
            conditionLayerSelected,
            'rgba(49, 180, 242, 0.05)',
            'rgba(255, 255, 255, 0.3)'
          ],
          'fill-antialias': true
        }}
        fillSortKey={['get', 'sortKey']}
        linePaint={{
          'line-color': [
            'case',
            conditionLayerCustom,
            'transparent',
            '#FFFFFF'
          ],
          'line-width': 2
        }}
        beforeId="symbol-layer"
      />

      {isGeoJSONLabelEnabled && (
        <GeoJSONLayer
          symbolLayout={symbolLayout}
          symbolPaint={symbolPaint}
          data={centroidGeoJSON}
        />
      )}
    </div>
  );
};

DynamicLayer.propTypes = {
  geoJSON: PropTypes.objectOf().isRequired,
  onClickShape: PropTypes.func.isRequired,
  labelKey: PropTypes.string.isRequired,
  isGeoJSONLabelEnabled: PropTypes.bool.isRequired
};

export default DynamicLayer;
