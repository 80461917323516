import Integrations from 'screens/Integrations';
import { IntegrationIcon } from 'routes/icons';
import MccainParentProvider from 'screens/Integrations/MccainIntegration/components/MccainParentProvider';
import SimplotParentProvider from 'screens/Integrations/SimplotIntegration/components/SimplotParentProvider';
import { paths } from 'routes/paths';
import ParentCropZoneMatcherProvider from 'screens/Integrations/CropZoneMatcher/components/ParentCropZoneMatcherProvider';
import AgrianParentProvider from 'screens/Integrations/AgrianIntegration/Processor/components/AgrianParentProvider';
import SimplotLandingPage from 'screens/Integrations/SimplotIntegration';
import AgrianLandingPage from 'screens/Integrations/AgrianIntegration/Processor';
import CalAgLandingPage from 'screens/Integrations/CalAgIntegration';
import CalAgParentProvider from 'screens/Integrations/CalAgIntegration/components/CalAgParentProvider';
import AgrianRecommendationLandingPage from 'screens/Integrations/AgrianIntegration/Recommendation';
import AgrianRecommendationParentProvider from 'screens/Integrations/AgrianIntegration/Recommendation/components/AgrianParentProvider';
import AgVendLandingPage from 'screens/Integrations/AgVend';

const routes = [
  {
    id: 'integrations',
    key: 'index',
    path: paths.integrations,
    exact: true,
    secure: true,
    disabled: false,
    icon: IntegrationIcon,
    nav: 'leftNav',
    component: Integrations
  },
  {
    id: 'integration',
    key: 'mcCain',
    path: `${paths.integrations}/McCain`,
    exact: true,
    secure: true,
    component: MccainParentProvider
  },
  {
    id: 'integration',
    key: 'simplot',
    path: `${paths.integrations}/Simplot/landing-page`,
    exact: true,
    secure: true,
    component: SimplotLandingPage
  },
  {
    id: 'integration',
    key: 'simplot-new-submission',
    path: `${paths.integrations}/Simplot`,
    exact: true,
    secure: true,
    component: SimplotParentProvider
  },
  {
    id: 'integration',
    key: 'simplot-view-submission',
    path: `${paths.integrations}/Simplot/submission/:id`,
    exact: true,
    secure: true,
    component: SimplotParentProvider
  },
  {
    id: 'integration',
    key: 'agrian-landing',
    path: `${paths.integrations}/Agrian/landing-page`,
    exact: true,
    secure: true,
    component: AgrianLandingPage
  },
  {
    id: 'integration',
    key: 'agrian-view-submission',
    path: `${paths.integrations}/Agrian/submission/:id`,
    exact: true,
    secure: true,
    component: AgrianParentProvider
  },
  {
    id: 'integration',
    key: 'agrian-new-submission',
    path: `${paths.integrations}/Agrian`,
    exact: true,
    secure: true,
    component: AgrianParentProvider
  },
  {
    id: 'integration',
    key: 'agrian-recommendation-landing',
    path: `${paths.integrations}/AgrianRecommendation/landing-page`,
    exact: true,
    secure: true,
    component: AgrianRecommendationLandingPage
  },
  {
    id: 'integration',
    key: 'agrian-recommendation',
    path: `${paths.integrations}/AgrianRecommendation`,
    exact: true,
    secure: true,
    component: AgrianRecommendationParentProvider
  },
  {
    id: 'integration',
    key: 'cal-ag',
    path: `${paths.integrations}/CalAgPermits`,
    exact: true,
    secure: true,
    component: CalAgParentProvider
  },
  {
    id: 'integration',
    key: 'cal-ag-landing',
    path: `${paths.integrations}/CalAgPermits/landing-page`,
    exact: true,
    secure: true,
    component: CalAgLandingPage
  },
  {
    id: 'integration',
    key: 'cal-ag-view-submission',
    path: `${paths.integrations}/CalAgPermits/submission/:id`,
    exact: true,
    secure: true,
    component: CalAgParentProvider
  },
  {
    id: 'integration',
    key: 'crop-zone-matcher',
    path: `${paths.integrationCropZoneMatcher}`,
    exact: true,
    secure: true,
    component: ParentCropZoneMatcherProvider
  },
  {
    id: 'integration',
    key: 'agvend-landing',
    path: `${paths.integrations}/AgVend/landing-page`,
    exact: true,
    secure: true,
    component: AgVendLandingPage
  }
];

export default routes;
