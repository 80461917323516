import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback
} from 'react';
import { format } from 'date-fns';
import Truncate from 'react-truncate';

import { Context } from 'components/Store';
import { CropZoneIcon, FarmIcon } from 'components/Icons';
import OrganizationIcon from 'components/Icons/components/OrganizationIcon';
import { filterUniqueItems } from 'screens/Property/helpers/propertyDataHelpers';

const containerClass = 'pr-4 flex flex-col mt-1 h-72px';
const textClass = 'text-xxs font-normal';
const flexColClass = 'flex flex-col text-10pt items-start';
const flexRowClass = 'flex flex-row w-full';
const flexRowFullClass = 'flex flex-row w-full items-center';
const ml1Class = 'ml-1';
const wFullClass = 'w-full';

const PropertyMapFooter = () => {
  const [
    { propertyMapFooterConfig, fieldsToMassiveAssign, organization }
  ] = useContext(Context);

  const [generatedDate, setGeneratedDate] = useState('');
  const [isCropSeasonsTruncated, setIsCropSeasonsTruncated] = useState(false);
  const [isFarmNamesTruncated, setIsFarmNamesTruncated] = useState(false);
  const cropSeasonsRef = useRef(null);
  const farmNamesRef = useRef(null);

  const cropSeasons = useMemo(
    () =>
      filterUniqueItems(fieldsToMassiveAssign, field =>
        field?.cropzones.flatMap(cropZone => cropZone?.cropSeasons ?? [])
      ),
    [fieldsToMassiveAssign]
  );

  const farmNames = useMemo(
    () => filterUniqueItems(fieldsToMassiveAssign, field => [field?.farmName]),
    [fieldsToMassiveAssign]
  );

  const renderItems = useCallback(
    (items, ref, keyExtractor, nameExtractor, onTruncate) => (
      <span ref={ref} className={`${wFullClass} ${ml1Class}`}>
        <Truncate lines={2} ellipsis={<span>...</span>} onTruncate={onTruncate}>
          {items.map((item, index) => (
            <span key={keyExtractor(item)}>
              {nameExtractor(item)}
              {index < items.length - 1 && ',\u00A0'}
            </span>
          ))}
        </Truncate>
      </span>
    ),
    []
  );

  useEffect(() => {
    const date = new Date();
    const formattedDate = format(date, 'MMM dd, yyyy hh:mm a');
    setGeneratedDate(`Generated on ${formattedDate}`);
  }, []);

  return (
    <div
      data-testid="footer-preview"
      className={containerClass}
      style={{ width: 'calc(100% - 216px)' }}
    >
      <div className={flexColClass}>
        {propertyMapFooterConfig.organizationName && (
          <div className={flexRowClass} data-testid="organization-name">
            <OrganizationIcon size={12} />
            <Truncate
              lines={1}
              ellipsis={<span>...</span>}
              className={ml1Class}
            >
              {organization.name}
            </Truncate>
          </div>
        )}
        <div
          className={`${flexRowClass} ${isFarmNamesTruncated ? textClass : ''}`}
        >
          {propertyMapFooterConfig.farmNames && (
            <div className={flexRowClass} data-testid="farm-names">
              <FarmIcon size={12} />
              {renderItems(
                farmNames,
                farmNamesRef,
                item => item,
                item => item,
                setIsFarmNamesTruncated
              )}
            </div>
          )}
        </div>
        <div
          className={`${flexRowFullClass} ${
            isCropSeasonsTruncated ? textClass : ''
          }`}
        >
          {propertyMapFooterConfig.cropSeasonNames && (
            <div className={flexRowFullClass} data-testid="crop-seasons">
              <CropZoneIcon size={12} />
              {renderItems(
                cropSeasons,
                cropSeasonsRef,
                item => item.id,
                item => item.name,
                setIsCropSeasonsTruncated
              )}
            </div>
          )}
        </div>
      </div>
      <div className={`${textClass} mt-2`}>{generatedDate}</div>
    </div>
  );
};

export default PropertyMapFooter;
